import { render, staticRenderFns } from "./Spotlight.vue?vue&type=template&id=6a5c0cce&"
import script from "./Spotlight.vue?vue&type=script&lang=ts&"
export * from "./Spotlight.vue?vue&type=script&lang=ts&"
import style0 from "./Spotlight.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AnimateText: require('/tmp/build_e5a2a944/src/components/animate/AnimateText.vue').default,Button: require('/tmp/build_e5a2a944/src/components/button/Button.vue').default,InlineVideo: require('/tmp/build_e5a2a944/src/components/media/InlineVideo.vue').default,AdaptiveImageNew: require('/tmp/build_e5a2a944/src/components/media/AdaptiveImageNew.vue').default})
